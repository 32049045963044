/* This files contains global styles, and also other imported style files */
// Importing Bootstrap SCSS file.
@import '~bootstrap/scss/bootstrap';
// Importing custom theme SCSS file.
@import './themes/default-app-theme.scss';

/* Variables */
$view-height: calc(100vh - 64px);

/* Styling */
// Set html / body
html, body { height: 100%; box-sizing: border-box; }
body { margin: 0; font-family: Montserrat, "Helvetica Neue", sans-serif; }
*, *:before, *:after {
  box-sizing: inherit;
}

/* Global classes */
.view-height {
  min-height: $view-height;
}

.header-height {
  min-height: $view-height;
}

.margin-up {
  margin-top: calc(-72px - 64px);
}

.css-1j8o68f {
  touch-action: none;
}
